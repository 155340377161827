<script setup>
const route = useRoute()
const { locale, setLocale } = useI18n()

import { useConfigStore } from "~/stores/config"
const configStore = useConfigStore()

const config = useRuntimeConfig()
const version = ref(`${config.public.appVersion}`)

const isActive = (path) => {
  if (Array.isArray(path)) {
    return path.some(singlePath => route.path.includes(singlePath))
  }
  return route.path === path
}
</script>

<template>
  <div class="print-hidden md:flex hidden md:w-1/4 lg:w-1/5 xl:w-1/6 max-w-[300px] min-h-screen min-h- p-8 flex-col text-indigo-950 bg-slate-100">
    <!-- logo toggle: @click.prevent="configStore.app.newLogo = !configStore.app.newLogo" -->
    <a href="/" class="flex align-middle items-center justify-center mb-16">
      <div v-if="configStore && configStore.app.newLogo" class="flex align-middle items-center justify-center">
        <img class="img-fluid" src="/assets/images/tgi-logo-new.svg" alt="TGI" width="80" />
        <span class="flex flex-col font-semibold font-logo leading-none tracking-tighter m-0 p-0 text-3xl ml-1 self-center text-primary">TGI</span>
      </div>
      <div v-else>
        <img class="img-fluid" src="/assets/images/tgi-logo-light.svg" alt="TGI" width="180" />
      </div>
    </a>
    <div class="flex flex-col flex-grow justify-between">
      <div>
        <nuxt-link to="/client/dashboard" role="button" class="menu-item"
          :class="{ active: isActive('/client/dashboard') }">
          <fa :icon="['fas', 'grid-2']" class="fa-fw text-xl" />
          <span class="ml-4 font-bold font-logo">{{ $t('menu.dashboard') }}</span>
        </nuxt-link>
        <nuxt-link to="/client/policies" role="button" class="menu-item"
          :class="{ active: isActive('/client/policies') }">
          <fa :icon="['fas', 'shield-halved']" class="fa-fw text-xl" />
          <span class="ml-4 font-bold font-logo">{{ $t('menu.policies') }}</span>
        </nuxt-link>
        <nuxt-link to="/client/claims" role="button" class="menu-item"
          :class="{ active: isActive(['/client/claims', '/client/newclaim', '/client/claim']) }">
          <fa :icon="['fas', 'memo-pad']" class="fa-fw text-xl" />
          <span class="ml-4 font-bold font-logo">{{ $t('menu.claims') }}</span>
        </nuxt-link>
        <nuxt-link to="/client/help" role="button" class="menu-item" :class="{ active: isActive('/client/help') }">
          <fa :icon="['fas', 'circle-question']" class="fa-fw text-xl" />
          <span class="ml-4 font-bold font-logo">{{ $t('menu.help') }}</span>
        </nuxt-link>
        <!--
        <nuxt-link to="/client/settings" role="button" class="menu-item" :class="{ active: isActive('/client/settings') }">
          <fa :icon="['fas', 'gear']" class="fa-fw text-xl" />
          <span class="ml-4 font-bold font-logo">Settings</span>
        </nuxt-link>
        -->
      </div>
      <div class="text-xs text-slate-400">
        v{{ version }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  @apply flex align-middle items-center mb-2 p-4;
}

.active {
  @apply bg-white rounded-xl shadow-lg;
}
</style>
